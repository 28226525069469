(function () {
    angular.module('informaApp')
        .component('whatsNewSection', {
            templateUrl: 'components/admin-sections/whats-new-section/template.html',
            controller: WhatsNewSection,
            bindings: {

            }
        });

    function WhatsNewSection($element, ModalHelper) {
        this.modes = {
            featureList: 1,
            newFeature: 2
        };

        this.currentMode = this.modes.featureList;

        this.changeMode = (mode) => {
            if (confirmDiscardingChanges(this, $element, ModalHelper)) {
                this.currentMode = mode;
            } else {
                this.awatingMode = mode;
            }
        };

        this.addNewFeature = () => {
            this.editableFeature = null;
            this.currentMode = this.modes.newFeature;
        };

        this.onDiscardChangesSubmit = () => {
            this.currentMode = this.awatingMode;
            this.awatingMode = null;
        };

        this.onEdit = (feature) => {
            this.editableFeature = feature;
            this.currentMode = this.modes.newFeature;
        };

        this.onCreatedEditedFeature = () => {
            this.currentMode = this.modes.featureList;
        };
    }

    function confirmDiscardingChanges(context, $element, ModalHelper) {
        if (context.currentMode === context.modes.newFeature) {
            ModalHelper.showModal($element.find('.modal'));
            return false;
        }

        return true;
    }
})();